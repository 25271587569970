import { AsyncPipe } from '@angular/common'
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Injector,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { ICON } from '@app/cnst/icons.cnst'
import { LinkSource, ROUTES } from '@app/cnst/nav.cnst'
import { isNativeApp, isWebApp } from '@app/cnst/userDevice.cnst'
import { AdminService } from '@app/srv/admin.service'
import { BootstrapService } from '@app/srv/bootstrap.service'
import { DeviceService } from '@app/srv/device.service'
import { di } from '@app/srv/di.service'
import { EventService } from '@app/srv/event.service'
import { InAppBrowserService } from '@app/srv/inappbrowser.service'
import { NavService } from '@app/srv/nav.service'
import { Priority } from '@app/srv/popup.cnst'
import { PopupController } from '@app/srv/popup.controller'
import { select2 } from '@app/srv/store.service'
import { TooltipService } from '@app/srv/tooltip.service'
import { UFService } from '@app/srv/uf.service'
import { UIService } from '@app/srv/ui.service'
import { buildInfo } from '@app/util/buildInfo.util'
import { addEventListenerNoZone, setTimeoutNoZone } from '@app/util/zone.util'
import { AppLauncher } from '@capacitor/app-launcher'
import {
  IonApp,
  IonBackButton,
  IonBackdrop,
  IonBadge,
  IonCheckbox,
  IonContent,
  IonFabButton,
  IonIcon,
  IonInfiniteScroll,
  IonRadioGroup,
  IonRouterOutlet,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonTabBar,
  IonTabButton,
  IonText,
} from '@ionic/angular/standalone'
import { _last, _objectKeys } from '@naturalcycles/js-lib'
import { getBucket } from '@naturalcycles/shared'
import { setContext, setTags } from '@sentry/core'
import { env } from '@src/environments/environment'
import { NCScreenshot } from '@src/typings/capacitor'
import { map, Subscription } from 'rxjs'
import { register } from 'swiper/element/bundle'
import { BlueDotComponent } from './cmp/blue-dot/blue-dot.component'
import { InspectorComponent } from './cmp/inspector/inspector.component'
import { RedDotComponent } from './cmp/red-dot/red-dot.component'
import { InfoModal } from './modals/info/info.modal'
import { ApiService } from './srv/api.service'
import { BluetoothService } from './srv/bluetooth.service'
import { distinctUntilDeeplyChanged } from './util/distinctUntilDeeplyChanged'
import { urlUtil } from './util/url.util'

// Swiper
register()

@Component({
  selector: 'app-root',
  styleUrls: ['app.component.scss'],
  templateUrl: 'app.component.html',
  imports: [
    AsyncPipe,
    RedDotComponent,
    BlueDotComponent,
    InspectorComponent,
    IonIcon,
    IonApp,
    IonRouterOutlet,
    IonContent,
    IonInfiniteScroll,
    IonRadioGroup,
    IonSegment,
    IonSegmentButton,
    IonBackdrop,
    IonCheckbox,
    IonBackButton,
    IonText,
    IonTabBar,
    IonTabButton,
    IonFabButton,
    IonBadge,
    IonSpinner,
  ],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor() {
    const injector = inject(Injector)

    di.init(injector) // must be called asap (here) to make di work
  }

  private adminService = inject(AdminService)
  private bootstrapService = inject(BootstrapService)
  private analyticsService = inject(AnalyticsService)
  private elementRef = inject(ElementRef)
  private inAppBrowserService = inject(InAppBrowserService)
  private tooltipService = inject(TooltipService)
  private renderer = inject(Renderer2)
  private ufService = inject(UFService)
  private deviceService = inject(DeviceService)
  private uiService = inject(UIService)
  private navService = inject(NavService)
  private bluetoothService = inject(BluetoothService)
  private apiService = inject(ApiService)

  private ghostLoader$ = select2(s => s?.ui.ghostLoader)
  private userSettings$ = select2(s => s?.userSettings)
  private partnerAccount$ = select2(s => s?.partnerAccount)
  private assignments$ = select2(s => s?.experiment.assignments)

  protected inspectorEnabled$ = select2(s => s?.ui.inspectorEnabled)
  protected swipeGesture$ = this.uiService.swipeGesture$
  protected overrideApiUrl = this.apiService.overrideApiUrl

  @HostBinding('class.partnerView')
  protected partnerView = false

  @HostBinding('class.ghost--loading')
  protected ghost = false

  @HostBinding('class.noFertilityStatus')
  protected noFertilityStatus = false

  @HostBinding('class.landscape')
  protected landscape = false

  @HostBinding('class.ncFrame')
  protected inFrame = this.deviceService.inFrame()

  readonly ver = buildInfo.ver

  protected ICON = ICON

  private subscriptions: Subscription[] = []

  // Cordova app: show if !env.prod
  // WebApp: show if !naturalcycles.com
  protected readonly showVersionTag = isNativeApp
    ? !env.prod
    : !location.href.includes('naturalcycles.com')

  protected bluetoothStatus$ = this.bluetoothService.bluetoothStatus$.pipe(
    map(status => (env.prod ? undefined : status)),
  )

  // ngAfterViewChecked() {
  //   incrementCdCount()
  // }

  public async ngOnInit(): Promise<void> {
    // Do UI and analytics stuff before bootstrapping
    this.subscriptions.push(
      this.ghostLoader$.subscribe(value => (this.ghost = !!value)),

      this.userSettings$.pipe(distinctUntilDeeplyChanged()).subscribe(userSettings => {
        setContext('User Settings state', (userSettings as any) || null)
      }),

      this.navService.navigationEnd$.subscribe(route => {
        this.landscape = route.startsWith(ROUTES.GraphPage)
      }),

      this.assignments$.pipe(distinctUntilDeeplyChanged()).subscribe(assignments => {
        if (!assignments) return
        for (const key of _objectKeys(assignments)) {
          const bucket = getBucket(assignments[key])
          if (!bucket) continue
          setTags({ [`AB-${key}`]: bucket })
        }
      }),
    )

    this.landscape = !!this.navService.getCurrentComponent()?.startsWith('Graph')

    await this.bootstrapService.bootstrap()

    // https://developers.google.com/web/tools/lighthouse/audits/passive-event-listeners
    addEventListenerNoZone('touchstart', () => this.onTouchStart(), {
      passive: true,
    })
    addEventListenerNoZone('mousedown', () => this.onTouchStart(), {
      passive: true,
    })

    this.subscriptions.push(
      this.ufService.showFertilityStatus$.subscribe(showStatus => {
        this.noFertilityStatus = !showStatus
      }),

      this.partnerAccount$.subscribe(partner => (this.partnerView = !!partner)),
    )
  }

  public async ngAfterViewInit(): Promise<void> {
    if (isWebApp) return

    const { available } = await NCScreenshot.available()
    if (!available) return

    void NCScreenshot.addListener('screenshot', () => {
      const page = this.navService.getCurrentComponent()

      const props: { page: string | undefined; slide?: string } = { page }

      if (page === 'GuidePage') {
        props.slide = this.uiService.getCurrentSwiperId(page)
      }

      void this.analyticsService.trackEvent(EVENT.SCREENSHOT, props)
    })
  }

  public ngOnDestroy(): void {
    void NCScreenshot.removeAllListeners()
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  private onTouchStart(): void {
    void this.tooltipService.removeAllTooltips(this.renderer)
  }

  // This listens to "Konami code" to activate the Red Dot
  @HostListener('window:keydown', ['$event'])
  protected onKeyPress(e: KeyboardEvent): void {
    void this.adminService.onKeyDown(e)
  }

  @HostListener('document:focusin', ['$event.target'])
  protected onFocusIn(target: Element): void {
    const elements = this.getAncestorsAndSelf(target)
    const focusableEl = elements
      .filter(el => el?.tagName)
      .find(
        el =>
          ['input', 'select', 'app-input'].includes(el.tagName.toLowerCase()) ||
          (!['button', 'a'].includes(el.tagName.toLowerCase()) && el.hasAttribute('uid')),
      )

    if (focusableEl) {
      void this.analyticsService.trackElement(focusableEl, EVENT.FOCUS)
    }
  }

  @HostListener('document:input', ['$event.target'])
  protected onInput(target: Element): void {
    const elements = this.getAncestorsAndSelf(target)
    const VALID = 'ng-valid'
    const INVALID = 'ng-invalid'
    const validatedEl = elements
      .filter(el => el?.classList && el.dataset)
      .find(el => el.classList.contains(VALID) || el.classList.contains(INVALID))

    if (validatedEl) {
      void this.analyticsService.trackElement(validatedEl, EVENT.INPUT_VALIDATION)
    }
  }

  @HostListener('document:click', ['$event.target', '$event'])
  protected async onClick(target: Element, event: any): Promise<void> {
    if (event.target.tagName === 'A') await this.handleLink(event)

    // Delay analytics and dom operations (perf)
    setTimeoutNoZone(() => {
      const elements = this.getAncestorsAndSelf(target)
      const clickableEl = elements
        .filter(el => el?.tagName)
        .find(el =>
          [
            'ion-button',
            'a',
            'ion-segment-button',
            'ion-fab-button',
            'ion-item',
            'ion-toggle',
          ].includes(el.tagName.toLowerCase()),
        )

      if (clickableEl) {
        void this.analyticsService.trackElement(clickableEl, EVENT.CLICK)
      }
    }, 1000)

    void this.handleTooltips(event)
  }

  private async handleLink(event: any): Promise<void> {
    event.preventDefault()

    const { origin, href } = event.target

    const path = href.split(origin)[1]

    if (path?.startsWith('/TOOLTIP=')) return
    if (path?.startsWith('/INFO?')) {
      const opened = this.openInfoModal(path)

      if (opened) return
    }

    if (href.startsWith('mailto')) return (window.location.href = href)

    if (href.startsWith('oura')) {
      void AppLauncher.openUrl({ url: href })
      return
    }

    const handled = !!(await di
      .get(NavService)
      .processInternalLink(path, LinkSource.IN_APP, origin))

    if (handled) {
      void di.get(PopupController).dismissActive()
      di.get(EventService).closeDrawer$.next()
      return
    }

    await this.inAppBrowserService.open(href)
  }

  private openInfoModal(path: string): boolean {
    const { title, body, id } = urlUtil.getQueryString(_last(path.split('?')))

    if (!(title && body && id)) return false // missing props

    void di.get(PopupController).presentModal(
      {
        component: InfoModal,
        componentProps: {
          title,
          body,
        },
      },
      id,
      Priority.IMMEDIATE,
    )

    return true
  }

  private async handleTooltips(event: any): Promise<void> {
    const target = event.target
    const elements = this.getTooltipElements(event)
    if (!target || !elements) {
      void this.tooltipService.removeAllTooltips(this.renderer)

      return
    }

    const container = this.tooltipService.getContainer(elements.clickableElement)

    const tooltip = await this.tooltipService.createTooltip(
      container,
      elements.clickableElement,
      event.pageX,
      elements.key,
    )

    event.preventDefault()
    await this.tooltipService.removeAllTooltips(this.renderer)
    void this.tooltipService.renderTooltip(tooltip, this.renderer)
  }

  private getTooltipElements(
    event: Event,
  ): { clickableElement: HTMLElement; parentElement: HTMLElement; key: string } | undefined {
    if (!event.target) return

    const elements = this.getAncestorsAndSelf(event.target as any)

    const clickableEl = elements
      .filter(el => el?.tagName)
      .find(el => el.tagName.toLowerCase() === 'a')
    if (!clickableEl) return undefined

    // Get Tooltip key from link
    const path = (clickableEl as any).pathname
    const hasTooltip = !!path && path.startsWith('/TOOLTIP=')
    if (!hasTooltip) return undefined

    // Get parent
    const parentEl = clickableEl.parentElement
    if (!parentEl) return undefined

    const key = path.replace('/TOOLTIP=', '')

    return {
      clickableElement: clickableEl,
      parentElement: parentEl,
      key,
    }
  }

  private getAncestorsAndSelf(el: Element): HTMLElement[] {
    let last = el as HTMLElement
    const elements: HTMLElement[] = []

    do {
      elements.push(last)
      last = last.parentNode as HTMLElement
    } while (last && last !== this.elementRef.nativeElement)

    return elements
  }
}
