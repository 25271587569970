<ion-app id="nc_app">
  @defer {
    <app-red-dot />
    <app-blue-dot />

    @if (inspectorEnabled$ | async) {
      <app-inspector />
    }

    @if (showVersionTag) {
      <div class="versionTag" role="complementary">{{ ver }}</div>
    }

    @if (bluetoothStatus$ | async; as status) {
      <div class="bluetoothTag">{{ status }}</div>
    }
  }

  <ion-router-outlet id="root_nav" [swipeGesture]="swipeGesture$ | async" />

  <ion-router-outlet name="morning" [swipeGesture]="swipeGesture$ | async" [animated]="false" />

  <!-- preloading ionic components -->
  <ion-content style="display: none">
    <!-- messages.page -->
    <ion-infinite-scroll [disabled]="true" />
    <!-- profile.page -->
    <ion-radio-group />
    <!-- add-data.modal -->
    <ion-segment [disabled]="true" />
    <ion-segment-button />
    <ion-backdrop [visible]="false" />
    <ion-checkbox />
    <ion-back-button />
    <ion-text />
    <ion-tab-bar />
    <ion-tab-button />
    <ion-fab-button />
    <ion-badge aria-hidden="true" />
    <ion-spinner />
  </ion-content>
</ion-app>

@if (inFrame) {
  <aside class="ncFrame__backdrop">
    <a href="/" title="Natural Cycles">
      <ion-icon class="ncFrame__logo" [src]="ICON.NC_MONOGRAM" color="logo" aria-hidden="true" />
    </a>
    <div class="ncFrame__stores">
      <a
        href="https://itunes.apple.com/app/naturalcycles/id765535549?ls=1&mt=8"
        target="_blank"
        title="Download app on App Store"
      >
        <img class="ncFrame__storesItem" src="../assets/img/footer-app-store.svg" alt="" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.naturalcycles.cordova&hl="
        target="_blank"
        title="Download app on Google Play"
      >
        <img class="ncFrame__storesItem" src="../assets/img/footer-google-play.svg" alt="" />
      </a>
    </div>
  </aside>
}

@if (overrideApiUrl()) {
  <div class="api-override-banner">⚠️ Using API: {{ overrideApiUrl() }}</div>
}
