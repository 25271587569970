import { CommonModule } from '@angular/common'
import {
  ApplicationRef,
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core'
import { bootstrapApplication, BrowserModule, enableDebugTools } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { provideRouter, RouteReuseStrategy, withPreloading } from '@angular/router'
import { horizontalSlideAnimation } from '@app/animations/horizontal-slide'
import { modalEnterAnimation, modalLeaveAnimation } from '@app/animations/vertical-slide'
import { AppComponent } from '@app/app.component'
import { AppErrorHandler } from '@app/app.error.handler'
import { AppPreloadingStrategy } from '@app/app.preloading.strategy'
import { routes } from '@app/app.router'
import { LangService } from '@app/srv/lang.service'
import { AppMissingTranslationHandler } from '@app/translate/missingTranslationHandler'
import { AppTranslateLoader } from '@app/translate/translateLoader'
import { Adjust } from '@awesome-cordova-plugins/adjust/ngx'
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone'
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha'
import { provideEnvironmentNgxMask } from 'ngx-mask'
import { env } from './environments/environment'

if (!env.dev) {
  enableProdMode()
}

/* eslint-disable unicorn/prefer-top-level-await */
bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes, withPreloading(AppPreloadingStrategy)),
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule.withConfig({
        disableAnimations: !('animate' in document.documentElement),
      }),
      CommonModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: AppTranslateLoader,
          // deps: [Platform, File],
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: AppMissingTranslationHandler,
        },
        defaultLanguage: 'en-US',
      }),
      RecaptchaV3Module,
    ),
    ReCaptchaV3Service,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: env.recaptchaSiteKey },
    Adjust,
    provideEnvironmentNgxMask(),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    {
      provide: LOCALE_ID,
      useFactory: (langService: LangService) => {
        return langService.locale
      },
      deps: [LangService],
    },
    provideIonicAngular({
      // backButtonText: '',
      // backButtonIcon: 'n/a',
      mode: 'md',
      navAnimation: horizontalSlideAnimation,
      modalEnter: modalEnterAnimation,
      modalLeave: modalLeaveAnimation,
      swipeBackEnabled: true,
      innerHTMLTemplatesEnabled: true,
      useSetInputAPI: true, // allow to use signal input in modals
      // rippleEffect: false,
      // animated: false,
    }),
  ],
})
  .then(moduleRef => {
    if (env.prod) return // don't enable in prod

    const applicationRef = moduleRef.injector.get(ApplicationRef)
    const [componentRef] = applicationRef.components
    // allows to run `ng.profiler.timeChangeDetection()` in console
    enableDebugTools(componentRef!)
  })
  .catch(err => console.log(err))
