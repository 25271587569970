import { Routes } from '@angular/router'
import { ROUTES } from '@app/cnst/nav.cnst'
import { ConnectedThermometerGuard } from '@app/guards/connected-thermometer.guard'
import { AddDataPageGuard } from '@app/pages/add-data/add-data.page.guard'
import { AuthPageGuard } from '@app/pages/auth/auth.page.guard'
import { CalibratePageGuard } from '@app/pages/calibrate/calibrate.page.guard'
import { CardsPageGuard } from '@app/pages/cards/cards.page.guard'
import { ConsentPageGuard } from '@app/pages/consent/consent.page.guard'
import { DeleteAccountPageGuard } from '@app/pages/delete-account/delete-acconut.page.guard'
import { ErrorPageGuard } from '@app/pages/error/error.page.guard'
import { GlossaryItemPageGuard } from '@app/pages/glossary-item/glossary-item.page.guard'
import { GraphPageGuard } from '@app/pages/graph/graph.page.guard'
import { GuidePageGuard } from '@app/pages/guide/guide.page.guard'
import { HomePageGuard } from '@app/pages/home/home.page.guard'
import { IntroPageGuard } from '@app/pages/intro/intro.page.guard'
import { OrderPageGuard } from '@app/pages/order/order.page.guard'
import { PartnerViewGuard } from '@app/pages/partner/partner.page.guard'
import { PartnerLoginPageGuard } from '@app/pages/partner-login/partner-login.page.guard'
import { PaymentPageGuard } from '@app/pages/payment/payment.page.guard'
import { canActivateQuiz } from './guards/can.activate.quiz'
import { AddressPageGuard } from './pages/address/address.page.guard'
import { canDeactivateFlowGuard } from './pages/flow/flow.deactivate.guard'
import { FlowGuard } from './pages/flow/flow.guard'
import { VerifyEmailPageGuard } from './pages/verify-email/verify-email.page.guard'

export const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.page.router').then(m => m.homeRoutes),
    canActivate: [HomePageGuard],
  },
  {
    path: 'intro',
    loadComponent: () => import('./pages/intro/intro.page').then(m => m.IntroPage),
    canActivate: [IntroPageGuard],
  },

  {
    path: 'auth',
    loadComponent: () => import('./pages/auth/auth.page').then(m => m.AuthPage),
    canActivate: [AuthPageGuard],
  },
  {
    path: 'verify-email',
    loadComponent: () =>
      import('./pages/verify-email/verify-email.page').then(m => m.VerifyEmailPage),
    canActivate: [VerifyEmailPageGuard],
  },
  {
    path: 'consent',
    loadComponent: () => import('./pages/consent/consent.page').then(m => m.ConsentPage),
    canActivate: [ConsentPageGuard],
  },
  {
    path: 'two-factor-authentication',
    loadComponent: () => import('./pages/auth-2fa/auth-2fa.page').then(m => m.Auth2FAPage),
    canActivate: [AuthPageGuard],
  },
  {
    path: 'graph',
    loadChildren: () => import('./pages/graph/graph.page.router').then(m => m.graphRoutes),
    canActivate: [GraphPageGuard],
  },
  {
    path: 'add-data',
    loadChildren: () => import('./pages/add-data/add-data.page.router').then(m => m.addDataRoutes),
    canActivate: [AddDataPageGuard],
  },
  {
    path: 'add-data',
    outlet: 'morning',
    loadComponent: () => import('./pages/add-data/add-data.page').then(m => m.AddDataPage),
    canActivate: [AddDataPageGuard],
  },
  {
    path: 'guide/battery-replacement',
    loadComponent: () =>
      import('./pages/guide/battery-replacement/battery-replacement-guide.page').then(
        m => m.BatteryReplacementGuidePage,
      ),
    canActivate: [ConnectedThermometerGuard],
  },
  {
    path: 'guide/:id',
    loadComponent: () => import('./pages/guide/guide.page').then(m => m.GuidePage),
    canActivate: [GuidePageGuard],
  },
  {
    path: 'calibrate',
    loadChildren: () =>
      import('./pages/calibrate/calibrate.page.router').then(m => m.calibrateRoutes),
    canActivate: [HomePageGuard, CalibratePageGuard],
  },
  {
    path: 'history',
    loadComponent: () => import('./pages/history/history.page').then(m => m.HistoryPage),
    canActivate: [HomePageGuard],
  },
  {
    path: 'pregnancy-dates',
    loadComponent: () =>
      import('./pages/pregnancy-dates/pregnancy-dates.page').then(m => m.PregnancyDatesPage),
    canActivate: [HomePageGuard],
  },
  {
    path: 'insights',
    loadChildren: () =>
      import('./pages/insights/insights.page.router').then(m => m.insightPageRoutes),
    canActivate: [HomePageGuard],
  },
  {
    path: 'pregnancy-calendar',
    loadComponent: () => import('./pages/home/month/month.page').then(m => m.MonthPage),
    canActivate: [HomePageGuard],
  },
  {
    path: 'checkout',
    loadComponent: () => import('./pages/checkout/checkout.page').then(m => m.CheckoutPage),
    canActivate: [HomePageGuard],
  },
  {
    path: 'shop',
    loadChildren: () => import('./pages/shop/shop.page.router').then(m => m.shopRoutes),
    canActivate: [HomePageGuard],
  },
  {
    path: 'thermometer',
    loadComponent: () =>
      import('./pages/thermometer/thermometer.page').then(m => m.ThermometerPage),
    canActivate: [HomePageGuard],
  },
  {
    path: 'oura-info',
    loadComponent: () => import('./pages/oura/oura-info/oura-info.page').then(m => m.OuraInfoPage),
    canActivate: [HomePageGuard],
  },
  {
    path: 'glossary/:id',
    loadComponent: () =>
      import('./pages/glossary-item/glossary-item.page').then(m => m.GlossaryItemPage),
    canActivate: [GlossaryItemPageGuard],
  },
  {
    path: 'error',
    loadComponent: () => import('./pages/error/error.page').then(m => m.ErrorPage),
    canActivate: [ErrorPageGuard],
  },
  {
    path: 'address',
    loadComponent: () => import('./pages/address/address.page').then(m => m.AddressPage),
    canActivate: [AddressPageGuard],
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment.page.router').then(m => m.paymentRoutes),
    canActivate: [PaymentPageGuard],
  },
  {
    path: 'order',
    loadComponent: () => import('./pages/order/order.page').then(m => m.OrderPage),
    canActivate: [OrderPageGuard],
  },
  {
    path: 'subscriptions-and-payments/manage',
    loadComponent: () =>
      import('./pages/manage-account/manage-account.page').then(m => m.ManageAccountPage),
    canActivate: [HomePageGuard],
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.page.router').then(m => m.supportRoutes),
    canActivate: [HomePageGuard],
  },
  {
    path: 'partner-login',
    loadComponent: () =>
      import('./pages/partner-login/partner-login.page').then(m => m.PartnerLoginPage),
    canActivate: [PartnerLoginPageGuard],
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.page.router').then(m => m.profileRoutes),
    canActivate: [HomePageGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.router').then(r => r.settingsRoutes),
    canActivate: [HomePageGuard],
  },
  {
    path: 'my-device',
    loadChildren: () =>
      import('./pages/my-device/my-device.page.router').then(m => m.myDeviceRoutes),
    canActivate: [HomePageGuard],
  },
  {
    path: 'refer',
    loadComponent: () => import('./pages/refer/refer.page').then(m => m.ReferPage),
    canActivate: [HomePageGuard],
  },
  {
    path: 'regulatory',
    loadComponent: () => import('./pages/regulatory/regulatory.page').then(m => m.RegulatoryPage),
  },
  {
    path: 'partner',
    loadChildren: () => import('./pages/partner/partner.page.router').then(m => m.partnerRoutes),
    canActivate: [PartnerViewGuard],
  },
  {
    path: 'delete-account',
    loadComponent: () =>
      import('./pages/delete-account/delete-account.page').then(m => m.DeleteAccountPage),
    canActivate: [DeleteAccountPageGuard],
  },
  {
    path: 'deleted-account',
    loadComponent: () =>
      import('./pages/deleted-account/deleted-account.page').then(m => m.DeletedAccountPage),
    canActivate: [DeleteAccountPageGuard],
  },
  {
    path: 'cards/:id',
    loadComponent: () => import('./pages/cards/cards.page').then(m => m.CardsPage),
    canActivate: [CardsPageGuard],
  },
  {
    path: 'mens-calendar',
    loadComponent: () =>
      import('./pages/mens-calendar/mens-calendar.page').then(m => m.MensCalendarPage),
    canActivate: [HomePageGuard],
  },
  {
    path: 'quiz',
    loadChildren: () => import('./pages/quiz/quiz.router').then(m => m.quizRoutes),
    canActivateChild: [canActivateQuiz],
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup.router').then(m => m.signupRoutes),
  },
  {
    path: 'flow/:flow', // :flow for FlowId
    loadChildren: () => import('./pages/flow/flow.router').then(m => m.flowRoutes),
    canActivate: [FlowGuard],
    canDeactivate: [canDeactivateFlowGuard],
  },
  // redirect pre-v4.1.0 routes
  {
    path: 'reminders',
    redirectTo: ROUTES.SettingsRemindersPage,
  },
  { path: '**', redirectTo: '/(morning:add-data)' },
  // { path: '**', redirectTo: 'home' },
  // { path: '**', redirectTo: '/intro' },
]
