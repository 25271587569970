import { Injectable } from '@angular/core'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { PageGuard } from '@app/guards/page.guard'
import { getNavParams } from '@app/srv/nav.service'
import { getState } from '@app/srv/store.service'
import { localDate } from '@naturalcycles/js-lib'
import { Goal, HardwareId } from '@naturalcycles/shared'

@Injectable({
  providedIn: 'root',
})
export class AddDataPageGuard extends PageGuard {
  className = 'AddDataPageGuard'

  get requirements(): boolean[] {
    const todayDate = localDate.todayString()
    const date = this.route.params['date'] || todayDate
    const sourcePath = getNavParams()[NavigationParams.SOURCE_PATH]
    const { account, addData, ui, userFertility } = getState()

    const autoOpenRequirements = [
      addData.lastAutoOpen !== todayDate,
      userFertility.entryMap && Object.keys(userFertility.entryMap).length > 2,
      !userFertility.entryMap?.[todayDate],
      !account.demoMode,
      todayDate !== account.completeDate,
      !ui.blockAutoOpen,
      ![HardwareId.APPLE_WATCH, HardwareId.OURA].includes(account.hwId),
      account.goal !== Goal.POSTPARTUM,
    ]

    const requirements = [
      !this.requiresAppConsent(),
      this.hasSession(),
      this.hasAccountCompleteDate(),
      this.hasUserFertilityTodayDate(),
      date <= todayDate,
      !!sourcePath ||
        autoOpenRequirements.every(Boolean) ||
        getState().account.personaId === 'PERCY',
    ]
    return requirements
  }
}
